<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <!-- 在 await dialog 显示的时候，不显示题目 -->
    <v-card v-if="!isShowAwaitDialog" flat max-width="800px" class="mx-auto">
      <div class="tester-toolbar">
        <v-btn
          small
          depressed
          text
          color="primary"
          @click="triggerAutoReadQues"
        >
          <v-icon class="mr-2">
            {{ isReadQues ? "mdi-account-voice" : "mdi-voice-off" }}
          </v-icon>
          {{ isReadQues ? "自动读题" : "读题关闭" }}
        </v-btn>
        <v-btn small depressed text color="primary" @click="isShowZdy = true">
          <v-icon class="mr-2">mdi-help-circle-outline</v-icon>
          指导语
        </v-btn>
        <v-btn
          small
          depressed
          text
          color="primary"
          @click="isShowSaveExitDialog = true"
        >
          <v-icon class="mr-2">mdi-content-save-edit-outline</v-icon>
          保存退出
        </v-btn>
        <v-btn
          small
          depressed
          text
          color="red"
          @click="isShowTerminateDialog = true"
        >
          <v-icon class="mr-2">mdi-text-box-remove</v-icon>
          取消测试
        </v-btn>
      </div>
      <div class="title-bar">
        <div class="lb-title-progress">
          <div class="lb-disp-title">
            {{ lbDetails.lbDispName }}
          </div>
          <div v-if="isShowProgress">
            <v-progress-linear
              color="primary"
              :value="answerProgress"
            ></v-progress-linear>
            <span class="text-caption">
              {{ answeredQuesCount }}/{{ totalQuesCount }}
            </span>
          </div>
        </div>
        <div class="timebox-progress">
          <v-progress-circular
            v-if="!!lbDetails.timeboxSeconds || isQuesTimeLimitMode"
            class="text-body-2"
            :rotate="-90"
            :size="70"
            :width="6"
            :value="timeboxProgress"
            color="teal"
          >
            {{ timeBoxRemainingStr }}
          </v-progress-circular>
          <div v-else class="text-body-1 mr-6 teal--text">
            {{ timeBoxRemainingStr }}
          </div>
        </div>
      </div>
      <div class="mt-4">
        <v-scroll-x-transition>
          <v-card v-show="isShowQues" class="px-2 py-2" elevation="6">
            <v-card-title>
              <span>{{ currentQuesTitle }}</span>
            </v-card-title>
            <v-card-text
              class="d-flex flex-wrap align-center"
              :class="{ 'justify-space-around': isPictureQues }"
            >
              <img
                v-if="isPictureQues"
                class="ques-img"
                :src="currentQuesWithAnswer.quesImg"
              />
              <v-radio-group
                class="mx-8 mt-8"
                :row="isPictureQues"
                v-model="currentQuesWithAnswer.answer"
                @change="saveOneQuesAnswer"
              >
                <v-radio
                  v-for="(opt, optIndex) in getTrimedOptList()"
                  :key="optIndex"
                  :value="optIndex"
                  :label="opt"
                  :disabled="isWidgetDisabled"
                  class="mb-8"
                  :ripple="false"
                >
                </v-radio>
              </v-radio-group>
            </v-card-text>
            <v-card-actions>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-btn
                      v-if="allowPrev"
                      color="primary"
                      block
                      depressed
                      :disabled="isWidgetDisabled"
                      @click="moveToPrevQues"
                    >
                      <v-icon small class="mr-2">mdi-arrow-left-bold</v-icon>
                      上一题
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      v-if="isReviewingAnswer && allowNext"
                      color="primary"
                      block
                      depressed
                      :disabled="isWidgetDisabled"
                      @click="moveToNextQues(false)"
                    >
                      下一题
                      <v-icon small class="ml-2">mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      v-if="isAllQuesAnswered"
                      color="success"
                      block
                      depressed
                      @click="isShowSubmitDialog = true"
                    >
                      <v-icon small class="mr-2">
                        mdi-checkbox-multiple-marked-circle-outline</v-icon
                      >
                      提交
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-scroll-x-transition>
      </div>
    </v-card>
    <AppDialog
      v-model="isShowAwaitDialog"
      persistent
      overlay-color="#ffffff"
      overlay-opacity="0.9"
      title="测量尚未开始"
      :show-cancel="false"
    >
      心理测量尚未开始，请等待主试人开启答题。
    </AppDialog>
    <AppDialog
      v-model="isShowZdy"
      persistent
      title="量表指导语"
      color="primary"
      action-text="我已知晓，开始答题"
      :show-cancel="false"
      @confirm="isShowZdy = false"
    >
      {{ lbDetails.zdy }}
    </AppDialog>
    <AppDialog
      v-model="isShowSubmitDialog"
      persistent
      size="small"
      title="确定要提交吗？"
      color="success"
      action-text="提交"
      :show-cancel="!isQuesTimeLimitMode"
      :loading="isBtnLoading"
      @confirm="submitTestResults"
    ></AppDialog>
    <AppDialog
      v-model="isShowTerminateDialog"
      size="small"
      title="确定要取消本次测试吗？"
      color="error"
      action-text="取消测试"
      :loading="isBtnLoading"
      @confirm="terminateTest"
    >
      <p>取消后答题结果会被删除，无法撤销。</p>
      <p>如需要保存测试进度然后退出，请点击“保存退出”。</p>
    </AppDialog>
    <AppDialog
      v-model="isShowSaveExitDialog"
      size="small"
      title="确定要保存测试进度并关闭吗？"
      color="primary"
      action-text="保存并退出"
      @confirm="saveTestProgress"
    >
      <p>保存当前答题进度，并退出本次答题。再次登录时可以继续答题。</p>
      <p>如需放弃本次答题，可点击“取消测试”</p>
    </AppDialog>
    <AppDialog
      v-model="isShowTimeupDialog"
      persistent
      size="small"
      title="答题已超时！"
      color="primary"
      action-text="已知晓，继续答题"
      :show-cancel="false"
      @confirm="isShowTimeupDialog = false"
    >
      <p>您的答题时间已经用完！</p>
      <p>请咨询您的心理医师，是否继续答题。</p>
      <template v-slot:action-ex>
        <v-btn text color="red" @click="isShowTerminateDialog = true">
          取消本次测试
        </v-btn>
      </template>
    </AppDialog>
    <AppMessageBox title="发生错误" v-model="errorMsg" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import { mapGetters, mapActions } from "vuex";
import {
  fetchLbDetailsFromGuid,
  fetchAllQuesWithAnsFromLbGuid,
  saveOneAnswerAndGetNext,
  startOneTest,
  completeOneTest,
  saveOneTest,
  terminateOneTest,
  fetchNextAnswerStatus,
  fetchPrevAnswerStatus
} from "@/api/sca";
import { fetchCanStartTestDirectly } from "@/api/dept";
import { splitSecondToTime, buildTimeString } from "@/utils/dateTime";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog
  },

  data() {
    return {
      isLoading: false,
      isKeyPressed: false,
      newCaseGuid: "",
      timeCost: 0,
      quesTimeCost: 0, // 单题的耗时
      optionZeroKeyNum: 0,
      lbDetails: {
        lbDispName: "",
        zdy: ""
      },
      isShowZdy: false,
      isShowQues: false,
      quesListWithAnswer: [],
      answerBeforeRadioChanged: -1,
      // Answer Status
      titleIndex: 0,
      quesIndex: -1,
      totalQuesCount: 0,
      answeredQuesCount: 0,
      allowPrev: true,
      allowNext: true,
      isShowProgress: true,
      timeLimitSec: 0,
      defaultAns: "",
      // 防止控件连续点击导致跳转错误的情况
      isWidgetDisabled: false,
      // 防止 destoryed 中把已提交的存为 saved 状态
      isTestSubmitted: false,
      isTestTerminated: false,
      // dialog
      isShowAwaitDialog: false,
      isShowSubmitDialog: false,
      isShowSaveExitDialog: false,
      isShowTerminateDialog: false,
      isShowTimeupDialog: false,
      isAlreadyTimeup: false,
      isBtnLoading: false,
      testTimer: null,
      // 朗读题目
      isReadQues: false,
      isRepeatSpeech: true,
      speechSynth: window.speechSynthesis,
      errorMsg: ""
    };
  },

  computed: {
    ...mapGetters({
      userGuid: "sca/userGuid",
      deptGuid: "sca/deptGuid",
      groupGuid: "sca/groupGuid",
      personGuid: "sca/personGuid",
      currentLbGuid: "sca/currentLbGuid"
    }),
    currentQuesWithAnswer() {
      let currentQues = this.quesListWithAnswer.find(
        qa => qa.quesIndex === this.quesIndex
      );
      return currentQues || {};
    },
    currentQuesTitle() {
      let title = this.currentQuesWithAnswer.quesTitle || "";
      if (this.titleIndex) {
        title = `${this.titleIndex}. ${title}`;
      }
      return title;
    },
    isQuesTimeLimitMode() {
      return !!this.timeLimitSec;
    },
    timeBoxRemaining() {
      if (!this.isQuesTimeLimitMode && this.lbDetails.timeboxSeconds) {
        // 没有单题时限，但是有量表时限的时候，使用 TimeCost 运算
        let sec = this.timeCost;
        let timeBox = Number(this.lbDetails.timeboxSeconds);
        if (timeBox >= this.timeCost) {
          // 倒数（时间大于零）
          sec = timeBox - this.timeCost;
        } else {
          // 正数（时间小于零）
          sec = (this.timeCost - timeBox) * -1;
        }
        return sec;
      } else if (this.isQuesTimeLimitMode) {
        // 有单题时限的时候，使用 QuesTimeCost
        return this.timeLimitSec - this.quesTimeCost;
      } else {
        // 没有时限的时候，显示 TimeCost
        return this.timeCost;
      }
    },
    timeboxProgress() {
      if (!this.isQuesTimeLimitMode && this.lbDetails.timeboxSeconds) {
        return (
          (this.timeBoxRemaining / Number(this.lbDetails.timeboxSeconds)) * 100
        );
      } else if (this.isQuesTimeLimitMode) {
        return (this.timeBoxRemaining / Number(this.timeLimitSec)) * 100;
      } else {
        return 0;
      }
    },
    timeBoxRemainingStr() {
      if (this.timeBoxRemaining) {
        let timeObj = splitSecondToTime(this.timeBoxRemaining);
        return buildTimeString(timeObj);
      }
      return "";
    },
    isPictureQues() {
      return !!this.currentQuesWithAnswer.quesImg;
    },
    answerProgress() {
      return (this.answeredQuesCount / this.totalQuesCount) * 100;
    },
    isReviewingAnswer() {
      return this.answerBeforeRadioChanged > -1;
    },
    isAllQuesAnswered() {
      return (
        this.totalQuesCount > 0 && this.answeredQuesCount >= this.totalQuesCount
      );
    }
  },

  methods: {
    ...mapActions({
      addToSubmittedCaseList: "sca/addToSubmittedCaseList",
      addToSavedCaseList: "sca/addToSavedCaseList",
      addToCancelledCaseList: "sca/addToCancelledCaseList",
      clearScaState: "sca/clearScaState"
    }),
    assignAnswerStatusFromResponse(response) {
      this.titleIndex =
        response.nextTitleIndex > 0 ? response.nextTitleIndex : this.titleIndex;
      this.quesIndex =
        response.nextQuesIndex > -1 ? response.nextQuesIndex : this.quesIndex;
      this.totalQuesCount = response.totalQuesCount;
      this.answeredQuesCount = response.answeredQuesCount;
      this.allowPrev = response.allowPrev;
      this.allowNext = response.allowNext;
      this.isShowProgress = response.isShowProgress;
      this.timeLimitSec = response.timeLimitSec;
      this.defaultAns = response.defaultAns;
    },
    // ============================ 量表初始化 =================================
    async getLbDetails() {
      try {
        this.lbDetails = await fetchLbDetailsFromGuid(this.currentLbGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    async getAllQuesListWithAnswer() {
      try {
        var res = await fetchAllQuesWithAnsFromLbGuid({
          userGuid: this.userGuid,
          groupGuid: this.groupGuid,
          lbGuid: this.currentLbGuid,
          personGuid: this.personGuid
        });
        this.newCaseGuid = res.caseGuid;
        this.timeCost = res.timeCost;
        this.optionZeroKeyNum = res.optionZeroKeyNum;
        this.quesListWithAnswer = res.quesAnsList.map(qa => {
          return {
            quesIndex: qa.quesIndex,
            quesTitle: qa.quesTitle,
            quesImg: qa.quesImg,
            optList: qa.optList,
            answer: Number(qa.answer)
          };
        });
        this.assignAnswerStatusFromResponse(res.nextAnsStatus);
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    async getCanStartTestDirectly() {
      if (this.deptGuid) {
        try {
          let canStartTest = await fetchCanStartTestDirectly(this.deptGuid);
          this.isShowAwaitDialog = !canStartTest;
        } catch (err) {
          this.errorMsg = err.message;
        }
      }
    },
    // ============================ 开始测试 =================================
    async startTheTest() {
      try {
        await startOneTest({
          caseGuid: this.newCaseGuid
        });
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    // ============================ 测试计时 =================================
    startTestTimer() {
      this.testTimer = setInterval(async () => {
        // TimeCost
        if (!this.isShowZdy && !this.isLoading && !this.isShowTimeupDialog) {
          // 显示指导语和timeup提示的时候不计时
          this.timeCost++;
        }
        if (
          !this.isQuesTimeLimitMode &&
          !this.isAlreadyTimeup &&
          !isNaN(this.lbDetails.timeboxSeconds) &&
          this.timeCost >= Number(this.lbDetails.timeboxSeconds)
        ) {
          // 在单题计时模式下，不触发超时弹窗
          this.isShowTimeupDialog = true;
          this.isAlreadyTimeup = true;
        }
        // QuesTimeCost: 单题计时，答完则停止计时
        if (
          !this.isShowZdy &&
          !this.isLoading &&
          this.isQuesTimeLimitMode &&
          this.quesTimeCost < this.timeLimitSec &&
          !this.isAllQuesAnswered
        ) {
          this.quesTimeCost++;
        }
        if (this.quesTimeCost === this.timeLimitSec) {
          // 需要事先把 quesTimeCost 清零，防止下一次 interval 继续进入此分支
          this.quesTimeCost = 0;
          // 单题时间到，强制跳题，
          await this.saveOneQuesAnswer(this.defaultAns);
        }
      }, 1000);
    },
    clearTestTimer() {
      clearInterval(this.testTimer);
    },
    getTrimedOptList() {
      let optList = this.currentQuesWithAnswer.optList;
      if (optList && optList.length) {
        return optList.filter(opt => !!opt);
      }
      return [];
    },
    startAnswerQues() {
      this.answerBeforeRadioChanged = this.currentQuesWithAnswer.answer;
      this.isShowQues = true;
      this.isWidgetDisabled = false;
    },
    // ============================ 答题状态 Answer Status =================================
    async getPrevAnswerStatus() {
      try {
        this.isLoading = true;
        let res = await fetchPrevAnswerStatus(this.newCaseGuid, this.quesIndex);
        this.assignAnswerStatusFromResponse(res);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async moveToPrevQues() {
      this.isWidgetDisabled = true;
      // 动画过渡
      this.isShowQues = false;
      this.transitionToQues(async () => {
        await this.getPrevAnswerStatus();
        this.answerBeforeRadioChanged = this.currentQuesWithAnswer.answer;
        this.isShowQues = true;
        this.isWidgetDisabled = false;
        this.speakQuesContent();
      });
    },
    async getNextAnswerStatus() {
      try {
        this.isLoading = true;
        let res = await fetchNextAnswerStatus(this.newCaseGuid, this.quesIndex);
        this.assignAnswerStatusFromResponse(res);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async moveToNextQues(
      isSavingAns,
      transitStartCallback,
      transitEndCallback
    ) {
      this.isWidgetDisabled = true;
      // 动画过渡，最后一题不做过渡动画
      this.allowNext && (this.isShowQues = false);
      this.transitionToQues(async () => {
        transitStartCallback && transitStartCallback();
        // 保存 Ans 时会自动获取 NextAnswerStatus，无需重复获取
        if (!isSavingAns) {
          await this.getNextAnswerStatus();
        }
        this.answerBeforeRadioChanged = this.currentQuesWithAnswer.answer;
        this.isShowQues = true;
        this.isWidgetDisabled = false;
        this.speakQuesContent();
        transitEndCallback && transitEndCallback();
      });
    },
    transitionToQues(nextTickCb) {
      this.stopReadingContent();
      // 跳题时，单题计时清零
      this.quesTimeCost = 0;
      setTimeout(() => {
        this.$nextTick(nextTickCb);
      }, 300);
    },
    // ============================ 题目朗读 =================================
    stopReadingContent() {
      // 停止朗读
      if (this.speechSynth) {
        this.isRepeatSpeech = false;
        this.speechSynth.cancel();
      }
    },
    triggerAutoReadQues() {
      if (this.speechSynth && this.isReadQues) {
        this.isReadQues = false;
        this.stopReadingContent();
      } else if (this.speechSynth && !this.isReadQues) {
        this.isReadQues = true;
        this.speakQuesContent();
      } else {
        this.isReadQues = false;
        this.errorMsg =
          "此浏览器不支持朗读功能, 请尝试其他浏览器, 或开启急速模式";
      }
    },
    getLocalChineseVoice() {
      if (this.speechSynth) {
        let voices = this.speechSynth.getVoices();
        if (voices && voices.length) {
          return voices.find(v => v.lang === "zh-CN" && v.localService);
        }
      }
      return "";
    },
    speakQuesContent(onStartCallback) {
      if (this.speechSynth && this.isReadQues) {
        // 正在朗读
        if (this.speechSynth.speaking) {
          return;
        }
        let readOptList = this.currentQuesWithAnswer.optList.map(
          opt => `选项${opt}`
        );
        let quesContent = `${
          this.currentQuesWithAnswer.quesTitle
        }。${readOptList.join("，")}`;
        let utter = new SpeechSynthesisUtterance();
        utter.lang = "zh-CN";
        utter.text = quesContent;
        utter.voice = this.getLocalChineseVoice();
        if (onStartCallback) {
          utter.onstart = onStartCallback;
        }
        utter.onend = e => {
          // 重复读题
          if (this.isRepeatSpeech) {
            this.speechSynth.speak(e.utterance);
          } else {
            this.isRepeatSpeech = true;
          }
        };
        this.speechSynth.speak(utter);
      }
    },
    // ============================ 保存答题结果和状态 =================================
    async saveOneQuesAnswer(newAnswer) {
      try {
        this.isLoading = true;
        let res = await saveOneAnswerAndGetNext({
          caseGuid: this.newCaseGuid,
          index: this.currentQuesWithAnswer.quesIndex,
          answer: `${newAnswer}`,
          timeCost: this.timeCost
        });
        if (this.answerBeforeRadioChanged < 0) {
          this.moveToNextQues(
            true,
            () => {
              this.assignAnswerStatusFromResponse(res);
            },
            () => {
              // 在单题计时模式中，最后一题答完或时间用完，则强制提交
              if (this.isQuesTimeLimitMode && this.isAllQuesAnswered) {
                this.isShowSubmitDialog = true;
              }
            }
          );
        }
      } catch (err) {
        this.errorMsg = `上传本题失败或网络超时，请重新作答，或等网络稳定后登录继续答题。${err.message}`;
        this.currentQuesWithAnswer.answer = this.answerBeforeRadioChanged;
      }
      this.isLoading = false;
    },
    async submitTestResults() {
      try {
        this.isBtnLoading = true;
        await completeOneTest({
          caseGuid: this.newCaseGuid,
          timeCost: this.timeCost
        });
        this.isTestSubmitted = true;
        // 提交成功后加入 submitted 的列表
        this.addToSubmittedCaseList({
          lbId: this.lbDetails.lbId,
          lbDispName: this.lbDetails.lbDispName,
          caseGuid: this.newCaseGuid
        });
        this.closeTester();
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isBtnLoading = false;
    },
    async saveTestProgress() {
      try {
        this.isBtnLoading = true;
        await saveOneTest({
          caseGuid: this.newCaseGuid,
          timeCost: this.timeCost
        });
        // 暂存测试进度后加入 saved 的列表
        this.addToSavedCaseList({
          lbId: this.lbDetails.lbId,
          lbDispName: this.lbDetails.lbDispName,
          caseGuid: this.newCaseGuid
        });
        this.closeTester();
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isBtnLoading = false;
    },
    async terminateTest() {
      try {
        this.isBtnLoading = true;
        await terminateOneTest({
          caseGuid: this.newCaseGuid
        });
        this.isTestTerminated = true;
        // 中止测试后加入 cancelled 的列表
        this.addToCancelledCaseList({
          lbId: this.lbDetails.lbId,
          lbDispName: this.lbDetails.lbDispName,
          caseGuid: this.newCaseGuid
        });
        this.closeTester();
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isBtnLoading = false;
    },
    closeTester() {
      // 返回量表列表界面
      this.$router.push({ name: "scastepper" });
    },
    // ============================ 键盘答题的绑定 =================================
    async bindOptionKeys(e) {
      if (
        !this.isKeyPressed &&
        !this.isWidgetDisabled &&
        !this.isShowZdy &&
        !this.isShowAwaitDialog &&
        !this.isShowSubmitDialog
      ) {
        this.isKeyPressed = true;
        // 最小选项为 1 时，最大选项为 length
        // 最小选项为 0 时，最大选项为 length - 1
        let keyRangeMin = Number(this.optionZeroKeyNum);
        let keyRangeMax = this.getTrimedOptList().length - 1 + keyRangeMin;
        let keyPressed = Number(e.key);
        if (
          !isNaN(keyPressed) &&
          keyPressed >= keyRangeMin &&
          keyPressed <= keyRangeMax
        ) {
          // 答案与按键的数字是不同的
          let answer = keyPressed - keyRangeMin;
          this.currentQuesWithAnswer.answer = answer;
          await this.saveOneQuesAnswer(answer);
          this.isKeyPressed = false;
        } else {
          this.isKeyPressed = false;
        }
      }
    }
  },

  mounted() {
    // 退出前的提示
    window.onbeforeunload = function(e) {
      // 兼容IE8和Firefox 4之前的版本
      if (e) {
        e.returnValue = "确定要关闭答题窗口吗？";
      }
      // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return "确定要关闭答题窗口吗？";
    };
  },

  async created() {
    this.isLoading = true;
    this.getCanStartTestDirectly();
    this.getLbDetails();
    await this.getAllQuesListWithAnswer();
    // 如果答题尚未开始，则通过轮询的方式获取最新状态
    let awaitInterval = setInterval(() => {
      if (this.isShowAwaitDialog) {
        this.getCanStartTestDirectly();
      } else {
        clearInterval(awaitInterval);
        this.startAnswerQues();
        this.startTheTest();
        this.isShowZdy = true;
        this.startTestTimer();
        document.onkeyup = this.bindOptionKeys;
        this.isLoading = false;
      }
    }, 1000);
  },

  destroyed() {
    if (!this.isTestSubmitted && !this.isTestTerminated) {
      // 未提交且未中止的情况下，关闭前自动保存
      saveOneTest({
        caseGuid: this.newCaseGuid,
        timeCost: this.timeCost
      });
    }
    this.clearTestTimer();
    this.stopReadingContent();
    document.onkeyup = null;
    window.onbeforeunload = null;
  }
};
</script>

<style lang="scss" scoped>
.tester-toolbar {
  text-align: right;
}
.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .lb-title-progress {
    width: 87%;
    margin: 0 15px 0 5px;
    .lb-disp-title {
      margin: 10px 0 5px 0;
      font-size: 22px;
    }
  }
  .timebox-progress {
    padding: 15px 0 0 10px;
  }
}
.ques-img {
  width: 90%;
}
</style>
